// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-evcharging-js": () => import("./../../../src/pages/evcharging.js" /* webpackChunkName: "component---src-pages-evcharging-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nexus-js": () => import("./../../../src/pages/nexus.js" /* webpackChunkName: "component---src-pages-nexus-js" */),
  "component---src-pages-parking-js": () => import("./../../../src/pages/parking.js" /* webpackChunkName: "component---src-pages-parking-js" */),
  "component---src-pages-roads-js": () => import("./../../../src/pages/roads.js" /* webpackChunkName: "component---src-pages-roads-js" */),
  "component---src-pages-shopsandrestaurants-js": () => import("./../../../src/pages/shopsandrestaurants.js" /* webpackChunkName: "component---src-pages-shopsandrestaurants-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */)
}

